// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-500-jsx": () => import("./../src/pages/500.jsx" /* webpackChunkName: "component---src-pages-500-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-menu-jsx": () => import("./../src/pages/menu.jsx" /* webpackChunkName: "component---src-pages-menu-jsx" */),
  "component---src-pages-nav-jsx": () => import("./../src/pages/nav.jsx" /* webpackChunkName: "component---src-pages-nav-jsx" */),
  "component---src-pages-search-jsx": () => import("./../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-templates-single-page-single-page-jsx": () => import("./../src/templates/SinglePage/SinglePage.jsx" /* webpackChunkName: "component---src-templates-single-page-single-page-jsx" */)
}

